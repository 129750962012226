import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Icons from "./helpers/icons";

import './App.css';


function App() {
  Icons()
  const [isShownLeft, setIsShownLeft] = useState(false);
  const [isShownRight, setIsShownRight] = useState(false);
  return <div className="row no-gutters ">
    <div className="col-md-6 "
      onMouseEnter={() => setIsShownLeft(true)}
      onMouseLeave={() => setIsShownLeft(false)}
    >
      <div className="left d-flex justify-content-center align-items-center">
        {isShownLeft && <div className='overlay '>
          <div className="content">
            <div className="contact-bullet-points">
              <div className="bullet-point-group">
                <div className="icon">
                  <FontAwesomeIcon icon="phone" />
                </div>

                <div className="text">+47 952 82 735</div>
              </div>

              <div className="bullet-point-group">
                <div className="icon">
                  <FontAwesomeIcon icon="envelope" />
                </div>
                <a className="text" href="mailto:jacobhnyman@gmail.com">jacobhnyman@gmail.com</a>
              </div>

              <div className="bullet-point-group">
                <div className="icon">
                  <FontAwesomeIcon icon="map-marked-alt" />
                </div>

                <div className="text">Oslo</div>
              </div>
            </div>
          </div>

        </div>}
        <img className={isShownLeft ? "up" : 'down'} src={require("./assets/LosProv.svg")} alt="Los Mexicanacas i Oslo" />
      </div>
    </div>
    <div className="col-md-6 "
      onMouseEnter={() => setIsShownRight(true)}
      onMouseLeave={() => setIsShownRight(false)}>
      <div className="right d-flex justify-content-center align-items-center">
        {isShownRight && <div className="overlay ">
          <div className="content">
            <div className="contact-bullet-points">
              <div className="bullet-point-group">
                <div className="icon">
                  <FontAwesomeIcon icon="phone" />
                </div>

                <div className="text">+47 464 21 155</div>
              </div>

              <div className="bullet-point-group">
                <div className="icon">
                  <FontAwesomeIcon icon="envelope" />
                </div>

                <a className="text" href="mailto:baldur.kjelsvik@gmail.com">baldur.kjelsvik@gmail.com</a>
              </div>

              <div className="bullet-point-group">
                <div className="icon">
                  <FontAwesomeIcon icon="map-marked-alt" />
                </div>

                <div className="text">Trondheim</div>
              </div>
            </div>
          </div>
        </div>}
        <img className={isShownRight ? "up" : 'down'} src={require("./assets/LosMex.svg")} alt="Los Mexicanacas i Trondheim" />
      </div>
    </div>
  </div>
}
export default App;
